.App {
  text-align: center;
  min-height: 100vh;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.h-100 {
  height: 100% !important;
}

.text-input::placeholder {
  color:#686868;
}

.text-align-center {
  text-align: center;
}

.transparent-btn {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  
  color: #686868;
}

.btn-back{
  margin-top: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #686868;
}

.ph-2 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.pv-3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pr-4 {
  padding-right: 4rem;
}

.mt-5 {
  margin-top: 5rem;
}

.mt-4 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mv-05 {
  margin: 0.5rem 0;
}

.mv-1 {
  margin: 1rem 0;
}

.rounded {
  border-radius: 20px;
}

.underline {
  text-decoration: underline;
}

.main-bg {
  background-color: #010C4C;
}

.pointer {
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  background-color: #B4B4B4;
  border-radius: 20px;
  border: 2px solid white;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
}

*::-webkit-scrollbar-button:increment, *::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar {
  width: 10px;
  -webkit-appearance: none;
}

.notification-container .notification {
  height: auto !important;
  display: block !important;
  white-space: normal !important;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.w-90 {
  width: 90% !important;
}