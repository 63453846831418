.get-payment-content {
  position: relative;
  display: flex;
  height: auto;
  min-height: calc(100vh - 204px);
  .title {
    font-size: 30px;
    font-weight: 400;
    width: 100%;
    margin-bottom: 20px;
  }

  .input-col {
    display: flex;
    flex-direction: column;
    width: 50vw !important;
    .comments-input {
      width: 50%;
      margin-top: 0.5rem;
      background-color: #d4d6e9;
      border-radius: 8px;
      margin-bottom: 20px;
    }
    .row-size {
      justify-content: center;
      .text-input::placeholder {
        color: rgba(0, 0, 0, 0.452);
        text-align: center;
      }
      .text-input--material {
        height: 31px;
        background-image: none;
      }
      .text-input--material__label {
        display: none;
      }
      .info {
        font-size: 18px;
      }
      span {
        color: #00003A;
        font-weight: 600;
      }
    }
    .row-margin-top {
      margin-top: 30px;
    }
    .row-amount {
      justify-content: center;
      .switch--material {
        height: 32px;
      }
      .switch {
        width: 120px !important;
        margin-bottom: 20px;
        .switch--material__toggle,
        .switch__toggle {
          margin-top: 0;
          box-shadow: inset 0 0 0 2px #d4d6e9;
          background-color: #D4D6E9;
          height: 32px;
          .switch--material__handle,
          .switch__handle {
            margin-top: 0;
            top: 0 !important;
            height: 32px;
            width: 65px !important;
            right: 55px !important;
            background-color: #00003A !important;
          }
          .switch__handle::after {
            content: "USD";
            color: white;
            padding-left: 15px;
            vertical-align: sub;
            font-size: 18px;
          }
        }
        .switch__toggle::after {
          content: "BTC";
          color: white;
          padding-left: 74px;
          vertical-align: sub;
          font-size: 18px;
        }
        :checked+.switch--material__toggle::after,
        :checked+.switch__toggle::after {
          content: "USD";
          padding-left: 14px;
        }
        :checked+.switch--material__toggle>.switch--material__handle,
        :checked+.switch__toggle>.switch__handle {
          left: 55px !important;
        }
        :checked+.switch--material__toggle>.switch--material__handle::after,
        :checked+.switch__toggle>.switch__handle::after {
          content: "BTC";
        }
        .switch--material__handle:before {
          content: initial;
        }
      }
      .convert{
        .switch__handle::after {
          content: "SI" !important;
          padding-left: 20px !important;
        }
        :checked+.switch__toggle>.switch__handle::after {
          content: "NO" !important;
        }
        .switch__toggle::after {
          content: "NO" !important;
          padding-left: 80px !important;
        }
        :checked+.switch__toggle::after {
          content: "SI" !important;
          padding-left: 25px !important;
        }
      }
      .switch-info {
        position: absolute;
        width: 20%;
        left: calc(50% + 75px);
        font-size: 13px;
        text-align: left;
      }
    }
    .row-amount.confirm-send {
      justify-content: flex-end;
      margin-right: 45px;
    }
    .row-amount.get-qr {
      width: auto;
      border: 8px solid;
      border-radius: 15px;
    }
    .row-size.confirm-send {
      align-self: flex-end;
    }
    .row-size.confirm-send.success-send{
      justify-content: center;
      background-color: white;
      font-size: 45px;
      color: #00FF00;
    }
    .circle-amount {
      width: 200px;
      height: 200px;
      background-color: #00003A;
      border-radius: 50%;
      .amount-money {
        color: white;
        font-size: 50px;
        font-weight: 600;
        line-height: 200px;
      }
    }
    .circle-amount.confirm-send {
      width: 250px;
      height: 250px;     
      .amount-money {
        font-size: 60px;
        line-height: 250px;
      }   
    }
    .circle-amount.confirm-send.success-send {
      background-color: #E6E6E6;
      .amount-money {
        color: black;
      }
    }
  }.input-amount {
    align-items: center !important;
  }
  .btn-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25vw !important;
    margin-bottom: 20px;
    .numpad {
      width: 65%;
      height: 75%;
      margin-bottom: 20px;
      border-radius: 5%;
      background-color: #d4d6e9;
      display: grid;
      grid-row-gap: 5px;
      .calculator-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7vw;
        cursor: pointer;
        font-size: 2rem;
        font-weight: 600;
        flex: 1;
      }
      .top-left {
        border-top-left-radius: 10px;
      }
      .top-right {
        border-top-right-radius: 10px;
      }
      .bottom-left {
        border-bottom-left-radius: 10px;
      }
      .bottom-right {
        border-bottom-right-radius: 10px;
      }
      .calculator-number:active {
        background-color: $text-gray;
      }
      .calculator-number.empty-number {
        cursor: default;
      }
      .calculator-number.empty-number:active {
        background-color: #d4d6e9;
        border-bottom-right-radius: 10px;
      }
    }
    .center-inline-button {
      margin-left: 90px;
    }
  }
  .btn-col.only-button {
    justify-content: flex-end;
  }

}

@include breakpoint($sm-desktop) {
  .get-payment-content {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
    .title {
      margin-top: 20px;
    }
    .input-col {
      .row-amount {
        .switch-info {
          width: 25%;
        }
      }
    }
  }
}

@include breakpoint($lg-phone) {
  .get-payment-content {
    .input-col {
      .comments-input {
        width: 70%;
      }
      .row-amount {
        .switch-info {
          width: 30%;
        }
      }
    }
    .btn-col {
      .numpad {
        width: 80%;
      }
    }
  }
}

@include breakpoint($m-phone) {
  .get-payment-content {
    .input-col {
      .row-amount {
        .switch-info {
          width: 33%;
        }
      }
    }
  }
}

@include breakpoint($phone) {
  .get-payment-content {
    .input-col,
    .btn-col {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      .numpad {
        height: 45vh;
      }
    }
    .input-col {
      .row-amount {
        .switch-info {
          width: 100%;
          left: 0;
          position: relative;
          text-align: center;
          margin-bottom: 20px;
          margin-top: -12px;
        }
      }
      .comments-input {
        width: 80% !important;
      }
    }
  }
}