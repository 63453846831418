.loading-screen {
  background-color: #010C4C;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
  
.loading-screen-image {
  padding-top: 100px;
  width: 300px;
}

.loading-screen-spinner {
  color: white;
  font-size: 1.5rem;
}