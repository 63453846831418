.header {
  display: inline-block;
  min-height: 150px;
  width: 100%;
}

.header-menu {
  display: none;
}

.avatar {
  display: flex;
  width: 70px;
  height: 70px;
  float: right;
  border-radius: 50%;
  border-style: solid;
  border-width: 5px;
  border-color: #010c4c;
  margin-top: 35px;
  margin-right: 60px;
}

.avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 10px;
}

.pencilImg{
  width: 30px !important;
  height: 30px !important;
  position: relative;
  right: -25px;
  top: -89px
}

.header-info {
  float: right;
  margin-top: 50px;
  margin-right: 15px;
  text-align: initial;
  font-size: 20px;
}

.welcome {  
  color: #b3b3b3;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 15px;
}

.name{
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-left: 7px;
}

.container-usd-btc{
  border: 1px solid #283090;
  border-radius: 15px;
  padding: 20px;
  width: 47%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

#btnTax-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btnTax{
  margin-bottom: 34px;
  margin-right: 24px;
  background: #666363;
  border-radius: 15px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  color: #fafafa;
  text-align: center;
  padding: 13px;

}

.payProccesBtn{
  margin: 0 auto;
  width: 50%;
  margin-bottom: 10px;
  background-color: #010C4C;
  border-radius: 10px;
}

.payProccesBtnTxt{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: white;
}

@media (max-width: 992px) {
  .header {
    display: flex !important;
    justify-content: center;
  }

  .avatar {
    margin: auto 15px;
    flex: 1;
    margin-top: 45px;
  }

  .header-info {
    text-align: start;
    flex: 8;
    margin-top: 60px;
  }

  .welcome {
    display: block;
    width: 26%;
  }

  .name {
    float: left;
    display: block;
    color: white;
    font-weight: 600;
  }

  .header-menu {
    display: block;
    font-size: 30px;
    color: white;
    margin-top: 10px;
    margin-left: 10px;
    flex: 2;
    cursor: pointer;
  }
}