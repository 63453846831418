.footer {
  display: inline-block;
  background: #E6E6E6;
  vertical-align: bottom;
  width: 100%;
  height: 50px;
}

.footer p {
  float: right;
  margin-right: 16px;
  color: #B4B4B4;
  font-weight: 600;
}

@media (max-width: 465px) {
  .footer p {
    font-size: 12px;
  }
}