.dialog-center {
  margin-left: 400px;
  margin-right: 400px;
}

.process-payments {
  .text-info {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #686868;
    padding-top: 11px;
    text-align: left;
    margin-left: 32px;
    letter-spacing: 0.02em;
  }

  .button-resize {
    display: flex !important;
    align-self: flex-start !important;
    margin: 0;
    font-size: 1.2rem;
    width: auto;
    text-transform: none;
    font-weight: 500;
    color: $white;
  }

  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    border-radius: 9px;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    background-color: #010C4C;
    font-style: normal;
    color: white;
    margin-left: 32px;
    margin-right: 32px;
  }

  .payments {
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 80vh;
  }

  .row-steps {
    width: 80%;
    background-color: $back-gray;
    align-self: center;
    border-radius: 10px;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 2rem;
    background: rgba(40, 48, 144, 0.2);
    border-radius: 7px;
    background: white;

    .option-step {
      padding: 0.9rem;
      width: 30%;
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      margin-right: 15px;
      background: rgba(40, 48, 144, 0.2);
      border-radius: 7px;
      color: #000000;
    }

    .back-blue {
      background-color: $back-blue;
      color: $white;
      border-radius: 7px;
    }
  }

  .payment-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .rounded-section {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      font-weight: 700;
      border-radius: 50%;
      background-color: #aaa;
      margin-bottom: 30px;

      .confirmation-amount {
        margin-bottom: 20px;
        font-size: 4rem;
      }

      .confirmation-method {
        font-size: 0.6rem !important;
      }
    }

    .message {
      margin-bottom: 1.2rem;
      margin-top: 0.2rem;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #686868;
      letter-spacing: 0.02em;
      width: 325px;
      font-weight: 700;
    }

    .amount-send {
      width: 30vw;
      justify-content: space-between;
      margin-bottom: 20px;

      .amount-money {
        flex: 1;
        margin-right: 10px;
        width: 175px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        word-wrap: break-word;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 100px;
        line-height: 120px;
        text-align: center;
      }

      .money-select {
        flex: 1;
        background-color: #010c4c;
        width: 200px;
        height: 4.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        text-align: center;
      }
    }

    .calculator {
      width: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 30px;
      width: 27vw;
      height: 50vw;
      background: #FFFFFF;
      border-radius: 13px 13px 0px 0px;
      box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.25);
      border: 10px solid #020047;

      .calculator-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 9vw;
        height: 4.2vw;
        cursor: pointer;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        color: #000000;
      }

      .top-left {
        border-top-left-radius: 10px;
      }

      .top-right {
        border-top-right-radius: 10px;
      }

      .bottom-left {
        border-bottom-left-radius: 10px;
      }

      .bottom-right {
        border-bottom-right-radius: 10px;
      }

      .calculator-number:active {
        background-color: $text-gray;
      }
    }

    .btn-amount {
      background-color: $back-blue;
      color: $white;
      border-radius: 10px;
      padding: 0.8rem;
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 2.5rem;
    }

    .payment-methods {
      width: calc(27vw + 80px);
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 35px;

      .payment-options {
        height: 77px;
        width: 50%;
        background-color: #ebebeb;
        border-radius: 0px 0px 10px 10px;
        align-items: center;
        padding: 0.6rem;

        cursor: pointer;

        .payment-icon {
          width: 2.8rem;
          margin-right: 1rem;
        }

        .text-box {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-family: 'Lato';
          font-style: normal;

          .payment-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.02em;
          }

          .payment-detail {
            font-weight: 700;
            font-size: 10.33px;
            line-height: 12.39px;
            letter-spacing: 0.02em;
          }

        }
      }

      .border-blue {
        background-color: #020047;

        .payment-title {
          color: #FFFFFF;
        }
      }

    }

    .payment-step2 {
      .payment-options {
        width: 50%;
        background-color: #ebebeb;
        border-radius: 7px 7px 0px 0px;
        height: 100%;
      }

      .border-blue {
        background-color: #020047;
        border-radius: 7px 7px 0px 0px;

        .text-box {
          color: #FFFFFF;
        }
      }

      .payment-chivo {
        width: 100%;

        .payment-icon {
          margin-left: 25px;
        }
      }

      .amount-btc {
        font-weight: 600;
      }
    }

    &#payment-step-2 {
      flex-direction: column;
      margin-top: 30px;
      display: flex;
      align-items: flex-start;

      .column-container {
        display: flex;
        width: 100%;
        flex-direction: row;

        .column-title {
          width: 100%;
          display: flex;
          text-align: left;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.02em;
          margin-bottom: 18px;
          margin-right: 22px;
          word-wrap: break-word;

          .column-title-number {
            color: #020047;
            margin-right: 5px;
          }

          .column-title-text {
            color: #676767;
          }

        }

        .column-1 {
          flex-basis: calc(43% - 20px);
          border-right: 1px solid #B3B3B3;
          margin-left: 20px;

          .info-box {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0.5vw;

            .total-send {
              height: 76px;
              background-color: #020047;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #FFFFFF;
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 29px;
              line-height: 35px;
              letter-spacing: 0.02em;
            }

            .timer {
              margin-top: 10px;

              .timer-number {
                font-family: 'Arial';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #020047;
                letter-spacing: -0.02em;
              }
            }

            .amount-btc {
              margin-top: 10px;
              background-color: #F7931A;
              border-radius: 16px;
              height: 23px;
              font-family: 'Lato';
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 16px;
              color: #FFFFFF;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .switch-row {
              align-items: center;
              justify-content: right;
              margin-top: 1rem;
        
              .switch-text {
                font-family: 'Arial';
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #676767;
                margin-right: 14px;
              }
            }
          }
        }

        .column-2 {
          flex-basis: 57%;
          margin-bottom: 19px;

          .column-title {
            margin-left: 36px;
          }

          .payment-step2 {
            margin: 0 auto;
            width: 85%;
            height: 82px;
          }

          .qr-code-box {
            margin: 0 auto;
            width: calc(85% - 80px);
            background-color: $back-gray;
            border-radius: 0px 0px 10px 10px;
            padding: 18.4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 16px 22px 16px 22px; 
            border-style: solid;
            border-color: #020047;
          }
        }
      }
    }
  }

  .step2-margin {
    margin-bottom: 5rem;
  }

  .row-btn {
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
    margin-bottom: 15px;

    .btn-steps {
      padding: 0.4rem 2.2rem;
      font-size: 1.2rem;
      font-weight: 400;
      background-color: $back-blue;
      color: $white;
      border-radius: 10px;
      text-transform: none;
      cursor: pointer;
    }

    .btn-gray {
      background-color: $back-gray;
      color: $black;
    }

    .btn-end {
      width: calc(27vw + 80px);
    }
  }

  .row-end {
    justify-content: center;
  }

  .step-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
    .btn-step-container {
      width: 43%;
      display: flex;
      justify-content: flex-start;

      .btn-gray {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 158px;
        height: 50px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 25px;
        letter-spacing: -0.02em;
      }
    }
    
    .wallet-container {
      width: 57%;
      display: flex;
      justify-content: center;

      .inner-wallet-container {
        display: flex;
        flex-direction: row;
        width: 85%;
        justify-content: flex-start;

        #btn-copy {
          height: 23px;
          width: 92px;
          font-family: 'Lato';
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: -0.02em;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 36px;
          border-radius: 6px;
        }
    
        .number-wallet {
          display: flex;
          width: 100%;
          background: #D1D2DC;
          border-radius: 16px;
          align-items: center;
          justify-content: center;
          padding: 0.5rem 1rem;
          font-family: 'Arial';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
          text-align: center;
          color: #676767;
          letter-spacing: -0.02em;
        }
      }
    }

  }
}

.payment-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;

  .rounded-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    font-weight: 700;
    border-radius: 50%;
    background-color: #aaa;
    margin-bottom: 30px;

    .confirmation-amount {
      margin-bottom: 20px;
      font-size: 4rem;
    }

    .confirmation-method {
      font-size: 0.6rem !important;
    }
  }

  .message {
    font-size: 1.1rem;
    margin-bottom: 1.2rem;
    margin-top: 0.2rem;
  }
}

@include breakpoint($m-desktop) {
  .process-payments {
    .payment-step {
      &#payment-step-2 {
        .column-container {
          .column-1 {
            flex-basis: calc(35% - 20px);
          }

          .column-2 {
            flex-basis: 65%;
          }
        }

        .btn-step-container {
          width: 35%;
        }

        .wallet-container {
          width: 65%;
        }
      }
    }
  }

  .transactions-card {
    .square {
      margin-left: 2px;
    }

    .desc {
      width: 115px;
    }

    .view {
      margin-left: 165px;
    }
  }

}

@include breakpoint($desktop) {
  .transactions-card {
    .amount {
      margin-left: 5px;
    }

    .square {
      margin-left: 5px;
    }
  }

  .transactions-card {
    .desc {
      width: 115px !important;
      margin-left: 12px
    }

    .view {
      margin-left: 124px;
    }

    .square {
      margin-left: 0px;
    }

    .amount {
      width: 133px !important;
    }
  }

}

@include breakpoint($lg-desktop) {
  .transactions-card {
    .view {
      margin-left: 165px;
    }
  }
}



@include breakpoint($sm-desktop) {
  .dialog-center {
    margin-left: 250px;
    margin-right: 250px;
  }

  .process-payments {
    .payment-step {
      .calculator {
        ons-row {
          height: 25%;

          .calculator-number {
            flex: 1 !important;
          }
        }
      }

      .amount-send {
        width: 30vw;

        .amount-money,
        .money-select {
          width: 14.2vw;
          height: 6.2vw;
        }
      }

      // .switch-row {
      //   margin-bottom: 1rem;
      // }

      &#payment-step-2 {
        .column-container {
          .column-1 {
            flex-basis: calc(30% - 20px);
          }

          .column-2 {
            flex-basis: 70%;
          }
        }

        .btn-step-container {
          width: 30%;
        }

        .wallet-container {
          width: 70%;
        }
      }
    }
  }
}

@include breakpoint($tablet) {
  .dialog-center {
    margin-left: 200px;
    margin-right: 200px;
  }

  .process-payments {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;

    .payments {
      align-items: center;

      .payment-step {

        &#payment-step-2 {
          .column-container {
            .column-1 {
              .info-box {
                width: 90%;

                .switch-row {
                  .switch-text {
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          
          .column-2 {
            .column-title {
              margin-left: 20px;
            }

            .payment-step2 {
              width: 92%;
            }

            .qr-code-box {
              width: calc(92% - 80px);
            }
          }
        }

        .calculator {
          width: 33vw;

          .calculator-number {
            height: 7.2vw;
          }
        }

        .payment-methods {
          width: calc(33vw + 79.2px);
        }

        .amount-send {
          width: 40vw;
          margin-top: 10px;
          margin-bottom: 40px;

          .amount-money,
          .money-select {
            width: 19.2vw;
          }
        }
      }
    }

    .btn-steps.btn-end {
      width: calc(33vw + 79.2px);
    }

    .transactions-card {
      .title {
        margin-right: 0px
      }
    }

    .step-2 {
      .wallet-container {
        .inner-wallet-container {
          width: 92%;
        }
      }
    }
  }
}

@include breakpoint($sm-tablet) {
  .dialog-center {
    margin-left: 10px;
    margin-right: 10px;
  }

  .process-payments {
    .payments {
      .payment-step {
        margin-top: 20px;

        .amount-send {
          width: 50vw;

          .amount-money {
            font-size: 80px;
          }
        }

        .calculator {
          width: 40vw;

          .calculator-number {
            height: 9.2vw;
            font-size: 1.6rem;
          }
        }

        .payment-methods {
          width: calc(40vw + 79.2px);
        }

        &#payment-step-2 {
          .column-container {
            flex-direction: column;

            .column-title {
              margin-right: 0px;
              margin-left: 0px;
              width: 92%;
            }

            .column-1 {
              margin-left: 0px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-self: center;
              align-items: center;
              border: 0;

              .info-box {
                width: 92%;
                padding-left: 0px;

                .switch-row {
                  justify-content: center;
                }
              }
            }

            .column-2 {
              display: flex;
              flex-direction: column;

              .column-title {
                align-self: center;
                margin-top: 1rem;
              }
            }
          }

          .inner-wallet-container {
            #btn-copy {
              margin-right: 20px;
              font-size: 0.9rem;
              padding: 0.4rem 1.5rem;
            }

            .number-wallet {
              font-size: 10px;
            }
          }
        }
      } 

      .btn-steps.btn-end {
        width: calc(40vw + 79.2px);
      }

      .btn-amount {
        font-size: 1.6rem;
      }
    }

    .row-steps {
      width: 90%;

      .option-step {
        font-size: 1rem;
      }
    }

    .payment-step {
      &#payment-step-2 {
        .step-2 {
          width: 92%;
          align-self: center;

          .btn-step-container {
            width: 25%;

            .btn-gray {
              width: 70%;
            }
          }

          .wallet-container {
            width: 75%;

            .inner-wallet-container {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($lg-phone) {
  .process-payments {
    .payments {
      .payment-step {
        .calculator {
          width: 41vh;
        }

        .payment-methods {
          width: calc(41vh + 79.2px);

          .payment-options {
            .text-box {
              .payment-title {
                font-size: 0.9rem;
              }

              .payment-detail {
                font-size: 0.5rem;
              }
            }

            .payment-icon {
              width: 2rem;
              margin-right: 0.3rem;
            }
          }
        }

        .amount-send {
          width: 60vw;

          .amount-money,
          .money-select {
            width: 28.2vw;
          }
        }

        .step-2 {
          .btn-step-container {
            .btn-gray {
              width: 50%;
              font-size: 18px;
            }
          }

          .wallet-container {
            .inner-wallet-container {
              #btn-copy {
                margin-right: 15px;
                padding: 0.4rem 1.8rem;
              }

              .number-wallet {
                font-size: 8px;
              }
            }
          }
        }
      }

      .btn-steps.btn-end {
        width: calc(41vh + 79.2px);
      }
    }
  }
}

@include breakpoint($m-phone) {
  .process-payments {
    .payments {
      .payment-step {
        .calculator {
          width: 57vw;

          .calculator-number {
            height: 10.2vw;
          }
        }

        .payment-methods {
          width: calc(57vw + 79.2px);
        }

        .amount-send {
          width: 70vw;
          margin-bottom: 30px;

          .amount-money,
          .money-select {
            width: 34.2vw;
            height: 12.2vw;
          }
        }

        &#payment-step-2 {
          .column-container {
            .column-title {
              font-size: 1.05rem;
            }
          }

          .step-2 {
            .btn-step-container {
              .btn-gray {
                font-size: 17px;
              }
            }
  
            .wallet-container {
              .inner-wallet-container {
                #btn-copy {
                  margin-right: 12px;
                  padding: 0.4rem 1.6rem;
                }
              }
            }
          }
        }
      }

      .btn-steps.btn-end {
        width: calc(57vw + 79.2px);
      }
    }

    .row-steps {
      width: 100%;
    }
  }

  .transactions-card {
    .amount {
      width: 105px;
    }

    .square {
      margin-left: 5px;
    }
  }



}

@include breakpoint($phone) {

  .process-payments {

    .payments {
      .payment-step {
        &#payment-step-2 {
          .payment-methods {
            .payment-options {
              .payment-icon {
                width: 2.7rem;
                margin-right: 0.7rem;
              }
  
              .text-box {
                .payment-title {
                  font-size: 17px;
                }
              }

              .payment-detail {
                font-size: 10px;
              }
            }
          }

          justify-content: center;

          .column-container {

            .column-title {
              font-size: 20px;
            }

            .column-1 {
              width: 100%;

              .info-box {
                .switch-row {
                  justify-content: right;
                }
              }
            }

            .column-2 {
              width: 100%;

              .payment-methods.payment-step2 {
                &#bitcoin-selected {
                  .payment-options {
                    width: 50%;
                  }
                }
              }
            }
          }

          .step-2 {
            width: 92%;
            align-self: center;
            flex-direction: column-reverse;
      
            .btn-step-container {
              .btn-gray {
                width: 70%;
              }
            }
      
            .wallet-container {
              width: 100%;
              margin-bottom: 20px;

              .inner-wallet-container {
                width: 100%;

                #btn-copy {
                  width: 20%;
                }
              }
            }
          }
        }
      }
    }
  }

  .mt-10px {
    margin-top: 5px;
  }

  .transactions-card {
    .desc {
      margin-bottom: 19px;

      .date {
        font-size: 13px;
      }

    }

    .amount {
      font-size: 12px;
      width: 91px;
    }

    .title {
      margin-right: 0px;
    }

    .view {
      margin-left: 50px;
    }
  }

  .process-payments {
    .button-section {
      margin-top: 15px;
      margin-left: 22px;
      margin-right: 22px;
    }

    .text-info {
      font-size: 0.9rem;
    }

    .payment-step {
      .calculator {
        .calculator-number {
          height: 10.2vw;
        }
      }

      .payment-methods {
        flex-direction: column;

        .payment-options {
          width: 100%;
        }
      }
      
      .amount-send {
        width: 80vw;

        .amount-money,
        .money-select {
          width: 38.2vw;
          height: 14.2vw;
        }
      }

      .btn-amount {
        font-size: 1rem;
      }
    }

    .row-steps {
      .option-step {
        font-size: 0.8rem;
      }
    }

    .row-btn .btn-steps {
      font-size: 0.8rem;
    }
  }
}

@include breakpoint($sm-phone) {
  .process-payments {
    .payments {
      .payment-step {

        .message {
          font-size: 1rem;
        }

        &#payment-step-2 {
          .payment-methods {
            .payment-options {
              .payment-icon {
                width: 1.7rem;
                margin-right: 0.4em;
              }

              .text-box {
                .payment-title {
                  font-size: 15px;
                }
              }

              .payment-detail {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
    .row-btn .btn-steps {
      font-size: 0.8rem;
    }

  }

  .transactions-card {
    .desc {
      .date {
        font-size: 13px;
      }

      title {
        margin-right: 40px;
      }

    }

    .square {
      margin-left: 5px;
    }

    .amount {
      width: 120px;
    }

    .view {
      margin-left: 48px;
    }
  }
}

@include breakpoint(300px) {
  .process-payments {
    .payments {
      .payment-step {
        &#payment-step-2 {
          .payment-methods {
            .payment-options {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}