.terminals {
  height: auto;
  min-height: calc(100vh - 204px);
}

.terminals-form {
  margin: 5px 0;
}

.content-title {
  display: flex;
  height: 45px;
  border-radius: 9px;
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  background-color: #010C4C;
  margin-bottom: 15px;
  color: white;
  justify-content: space-around;
  margin-left: 32px;
  margin-right: 32px;
}

#transaction-txt{
  flex: 0.5;
  text-align: left;
}

.report-btn-container{
  margin-left: 0 auto;
  border: 1px solid #CCCCCC;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 7px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 12px;
}

.reportImg{
  align-self: center;
}

.flex-container{
  display: flex;
}

.report-btn{
  box-sizing: border-box;
  border-radius: 7px;
  background-color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  align-self: center;
}

.form-row {
  justify-content: space-evenly;
}

.form-col-input {
  background-color: #E6E6E6;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  padding: 5px 0;
}

.form-label {
  text-align: start;
  margin-left: 10px;
}

.form-input {
  font-size: 2rem;
  background-color: #E6E6E6;
  border-radius: 10px;
  width: 90%;
}

.form-cell {
  font-size: 20px;
  background-color: #E6E6E6;
  border-radius: 10px;
}

.form-input input {
  text-align: center;
  font-size: 17px !important;
}

input.form-input {
  text-align: center;
  font-size: 17px !important;
  border: none
}

.form-input .text-input {
  color: black;
  font-size: 15px;
}

.form-input input:disabled {
  color: black !important;
}

.form-input .text-input::placeholder {
  color: black;
}

.status-label {
  display: inline-block;
  vertical-align: middle;
  margin-top: 10px;
  font-size: 20px;
}

.white-check :checked+.checkbox--noborder__checkmark:before,
.white-check :checked+.checkbox--noborder__checkmark:after {
  color: white;
  border-color: white !important;
}

.status-icon {
  margin-left: 10px;
  margin-top: 13px;
  vertical-align: middle;
  border-radius: 5px;
  border: 2px solid #646464;
}

.checkbox--noborder__checkmark:after {
  border-color: #646464 !important;
}

.button-add {
  padding-left: 40px;
  height: 45px;
  padding-top: 7px;
  margin-bottom: 25px;
}

.button-icon {
  position: absolute;
  left: 12px;
}

.paginator {
  margin-right: 5vw;
  display: flex;
  justify-content: flex-end;
}

.page-number {
  display: inline-block;
  margin-left: 15px;
  align-self: center;
}

.form-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.form-buttons .button {
  width: 165px !important;
  padding-top: 5px !important;
  height: 40px !important;
}

.navigator {
  margin-left: 15px;
  align-self: center;
}

.form-col-input.col-adduser {
  height: 60px;
  margin-bottom: 15px;
}

.mh-400{
  margin-left: 400px;
  margin-right: 400px;
}

.text-error {
  color: red;
  font-size: 0.8rem;
}

.terminals .form-cell {
  font-size: 15px;
}



@media (max-width: 1360px) {
  .terminals .status-label {
    font-size: 15px;
  }
}

@media (max-width: 1090px) {
  .terminals .form-cell {
    font-size: 12px;
  }
  .terminals .status-label {
    font-size: 12px;
  }
}

@media (max-width: 992px) {  
  .terminals{
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .terminals .form-cell {
    font-size: 12px;
  }
  .terminals .status-label {
    font-size: 16px;
  }
  .terminals .checkbox--material__checkmark:before {
    width: 15px;
    height: 15px;}
  .terminals .checkbox--material {
    width: 15px;
    height: 15px;}
  .terminals .checkbox--material__checkmark {
    width: 15px;
    height: 15px;
  }
  .terminals :checked+.checkbox--material__checkmark:after {
    width: 8px;
    height: 4px;
  }
}

@media (max-width: 660px) {
  .terminals .form-cell {
    font-size: 12px;
  }
  .terminals .status-label {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .form-col-input {
    font-size: 14px;
  }

  .terminals .form-cell {
    font-size: 12px;
  }

  .terminals .status-label {
    font-size: 12px;
  }

  .terminals .checkbox--material__checkmark:before {
    width: 12px;
    height: 12px;
  }

  .terminals .checkbox--material {
    width: 12px;
    height: 12px;
  }

  .terminals .checkbox--material__checkmark {
    width: 12px;
    height: 12px;
  }
  
  .terminals :checked+.checkbox--material__checkmark:after {
    width: 4px;
    height: 2px;
  }
}

@media (max-width: 480px) {
  .terminals div:nth-child(2) {
    border-radius: initial;
  }

  .content-title {
    margin-top: 15px;
    margin-left: 22px;
    margin-right: 22px;
  }

  .rdt_Table {
    overflow-y: scroll !important;
    max-width: 120% !important;
    width: 120% !important;
  }
}

@media (max-width: 370px) {
  .form-col-input {
    font-size: 12px;
  }
  .rdt_Table {
    max-width: 150% !important;
    width: 150% !important;
  }
  .terminals-form.add-form ons-col {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }
}
