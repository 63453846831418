.wrapper {
  display: flex;
  background-color: white;
  min-height: 100vh;
}

.container {
  width: 75%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  display: inline-block;
  width: 100%;
  min-height: 400px;
}

.content.dashboard {
  height: auto;
  min-height: calc(100vh - 204px);
}

.dashboard .content-title {
  height: 45px;
  border-radius: 9px;
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  background-color: #010C4C;
  color: white;
  margin-left: 32px;
  margin-right: 32px;
}

.cards {
  width: 100% !important;
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 30rem), 1fr));
}

.transactions-card {
  display: inline-block;  
  min-height: 250px;
  height: fit-content;
  border-width: 1px;
  border-radius: 10px;
  margin: 40px;
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, 0.2);
  vertical-align: top;
  padding: 25px 0;
  margin-bottom: 100px;
}

.transactions-card .title {
  display: inline-block;
  margin-right: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
}

.transactions-card .view {
  display: inline-block;
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 16px;
  color: #283090;
}

.transactions-card .square {
  display: inline-block;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 30px;
}

.mt-10px {
  margin-top: 10px;
}

.transactions-card .blue {
  background-color: #283090;;
}
.transactions-card .orange {
  background-color: #F7931A;
}
.transactions-card .grey {
  background-color: #606060;
}

.transactions-card .desc {
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 25px;
  text-align: left;
  vertical-align: bottom;
  line-height: 25px;
  width: 150px;
}

.transactions-card .desc.uuid {
  width: 10% !important;
  margin-bottom: 3%;
  margin-left: 40px;
}

.transactions-card .desc .date {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 12px;
  color: #000000;
  opacity: 0.3;
}

.transactions-card .desc .type {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 14px;
    margin-top: 5px;
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 20px;
}

.star:before,
.star:after {
  content: "";

  display: block;
  width: 0;
  height: 0;

  position: absolute;
  top: 0.6em;
  left: -1em;

  border-right: 1em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 1em solid transparent;

  transform: rotate(-35deg);
}

.star:after {
  transform: rotate(35deg);
}

.transactions-card .amount {
  display: inline-block;
  vertical-align: top;
  width: 150px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 13px;
  text-align: right;
  color: #020047;
}

.calc-card {
  display: inline-block;
  margin: 15px;
  height: 100%;
  vertical-align: top;
}

.calc-card .usd-btc {
  display: flex !important;
  flex-direction: column;
  flex: .4;
}

.usd-title-bg{
  background: #283090;
  border-radius: 12px
}

.btc-title-bg{
  background: #F7931A;
  border-radius: 12px;
}

.usd-btc-title {
  align-self: center;
  padding-bottom: 0.8rem;
  color: white;
  padding: 8px;
  width: 40px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
}

.usd-btc-amount {
  align-self: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 4px;
}

.usd-btc-result {
  align-self: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.calc-card .usd {
  display: inline-block;
  flex: .4;
}

.calc-card .btc {
  display: inline-block;
}

.flex-cardTitle{
  display: flex;
  margin-bottom: 25px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flex-cardRow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.button-blue {
  display: block;
  padding-top: 5px;
  width: 194px;
  height: 40px;
  margin: 0 auto;
  background: #283090;
  font-size: 15px;
  border-radius: 10px;
  margin-top: 15px;
  color: white;
  font-weight: 600;
}

.action-btn {
  margin: 1rem 0.5rem 2rem 0.5rem;
  width: 41%;
  height: 40px;
  background: #010c4c;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  font-weight: 600;
}

.excel-btn {
    width: 100%;
    height: 40px;
    background-color: white;
    border-radius: 10px;
    font-weight: 600;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}




.action-btn a {
  color: white;
  text-decoration: none;
}

.inline-button {
  display: inline-block;
  width: 150px !important;
  margin: 5px;
  padding-top: 0;
  height: 32px;
}

.button-process {
  margin-left: 250px;
  width: 150px;
}

.square.blue img {
  width: 25px;
  margin-top: 10px;
}
.process-payments-button {
  display: none;
}

.dashboard .exchange-button {
  text-decoration: none;
}

@media (max-width: 992px) {  
  .container {
    width: 100%;
    background-color: #010c4c;
  }

  .content.dashboard  {
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }

  .dashboard .process-payments-link {
    display: none;
  }

  .dashboard .process-payments-button {
    display: block;
    text-decoration: none;
  }

  .dashboard .button-blue {
    width: 265px;
  }
  
  .dashboard .button-blue.inline-button {
    width: 125px !important;
  }
}

@media (min-width: 1538px) {
  .dashboard .cards {
    grid-template-columns: 45% 45%;
  }
}

@media (max-width: 465px) {
  .transactions-card .desc .date{
    font-size: 12px;
    line-height: 12px;
  }

  .transactions-card .desc .type{
    font-size: 12px;
    line-height: 14px;
  }

  .dashboard .desc {
    width: 95px;
    font-size: 15px;
  }

  .dashboard .amount {
    width: 6rem !important;
    font-size: 15px;
  }


  .transactions-card .view {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  .dashboard .content-title {
    margin-top: 15px;
    margin-left: 22px;
    margin-right: 22px;
  }
}

@media (max-width: 415px) {


  .dashboard .desc {
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 0;
  }

  .dashboard .type {
    font-size: 15px;
    line-height: 14px;
  }


  .dashboard .amount {
    width: 4rem !important;
    font-size: 12px;
  }

  .dashboard .button-blue {
    width: 230px;
  }
  
  .dashboard .button-blue.inline-button {
    width: 110px !important;
  }

  
  .dashboard .usd-btc-amount {
    font-size: 16px;
    font-weight: 600;
  }
  
  .dashboard .usd-btc-result {
    font-size: 12px;
  }

  .transactions-card .square {
    height: 40px;
    width: 40px;
  }

  .square.blue img {
    width: 20px;
  }

  .star {
    font-size: 16px;
  }

}