.hkcGBu {
  white-space: normal !important;
}

.content.transactions {
  height: auto;
  min-height: calc(100vh - 204px);
}

.transactions .content-title {
  align-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 9px;
  font-family: 'Lato';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  background-color: #010C4C;
  color: white;
}

.transactions .usd-btc {
  display: inline-block;
  margin-top: 20px;
  line-height: 30px;
}

.transactions .usd {
  display: inline-block;
  margin-right: 60px;
}

.transactions .btc {
  display: inline-block;
  margin-left: 60px;
}

.transactions .usd-btc-title {
  font-size: 2.5rem;
  padding-bottom: 0.8rem;
}

.transactions .usd-btc-amount {
  font-size: 25px;
  font-weight: 600;
}

.transactions .usd-btc-result {
  font-size: 14px;
}

.transactions .react-datepicker-wrapper {
  width: auto;
}

.transactions .select-type {
  display: inline-block;
  vertical-align: top;
  padding-top: 0;
  width: 265px !important;
}

.transactions .select-type .type-icon{
  float: left;
  vertical-align: middle;
  margin-top: 10px;
  margin-left: 25px;
}

.transactions .select {
  display: inline-block;
  vertical-align: top;
  padding-top: 0;
  width: 80%;
}



.transactions .select select {
  color: white;
  text-align-last: center;
  font-weight: 600;
  padding-top: 4px;
  background: 
    linear-gradient(45deg, transparent 50%,white 50%),
    linear-gradient(135deg, white 51%, transparent 50%);
  background-position: 
    calc(100% - 8px) calc(1em + 2px),
    calc(100% - 3px) calc(1em + 2px),
    100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}
.transactions .select select option{
  color: black;
}

.transactions .select-type .select {
    width: 75%;
}
.transactions .select-type select option{
  color: black;
}

.transactions .select-type select {
  color: white;
  text-align-last: center;
  font-weight: 600;
  padding-top: 4px;
  background: 
    linear-gradient(45deg, transparent 50%,white 50%),
    linear-gradient(135deg, white 51%, transparent 50%);
  background-position: 
    calc(100% - 8px) calc(1em + 2px),
    calc(100% - 3px) calc(1em + 2px),
    100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.transactions .arrow-down {
  width: 15px;
  height: 15px;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  background: 
    linear-gradient(45deg, transparent 50%,white 50%),
    linear-gradient(135deg, white 51%, transparent 50%);
  background-position: 
    calc(100% - 7px) calc(1em + -12px),
    calc(100% - 2px) calc(1em + -12px),
    100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

.transactions .middle-picker {
  margin: 0 10px;
}

.transactions .react-datepicker__tab-loop{
  display: inline-block;
}

.transactions .dateIcon {
  float: left;
  margin-left: 5px;
  margin-top: 5px;
}

.transactions .button-blue {
  width: 170px;
}

.transactions.options-container {  
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 15px 10px
}

.transactions .export-option {
  margin: unset;
}

.transactions .table-cell {
  background-color: #E6E6E6;
  padding-top: 10px;
}

.transactions .row-header {
  margin-top: 15px;
  border-radius: 10px 10px 0 0;
  padding-top: 30px;
}

.transactions .row-bottom {
  border-radius: 0 0 10px 10px;
  padding-bottom: 15px;
}

.transactions .row-header-bottom {
  margin-top: 15px;
  border-radius: 10px 10px 10px 10px;
  padding-top: 30px;
  padding-bottom: 15px;
}

.transactions .row-cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.transactions .row-separator {
  height: 2px;
  background-color: #CCCCCC;
  margin-right: 25px;
  margin-left: 25px;
}

.transactions .desc {
  display: inline-block;
  margin-left: 25px;
  text-align: left;
  vertical-align: bottom;
  line-height: 25px;
  width: 220px;
}

.transactions .desc.uuid {
  width: 10% !important;
  margin-bottom: 3%;
  margin-left: 40px;
}

.transactions .desc .date {
  color: #666666;
}

.time

.transactions .desc .type {
  font-size: 20px;
}

.transactions .amount {
  display: inline-block;
  margin-left: 25px;
  vertical-align: top;
  margin-top: 10px;
  width: 150px;
}
.transactions .amount .amount-usd {
  font-weight: 800;
  font-size: 15px;
}

.transactions .amount .amount-btc {
  font-size: 12px;
  color: #666666;
}

.transactions .status-mark {
  display: inline-block;
  font-size: 20px;
  vertical-align: top;
  margin-left: 45px;
  margin-top: 18px;
  height: 24px;
  width: 24px;
}

.transactions .square {
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 30px;
}

.transactions .square.blue img {
  width: 25px;
  margin-top: 10px;
}

.transactions .receive {
  font-size: 16px;
  color: white;
  margin-top: 12px;
  border-top: 3px solid;
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  width: 17px;
  margin-left: 12px;
  height: 20px;
}

.transactions .receive-icon {
  margin-left: 5px;
  margin-top: 2px;
}

.transactions .send {
  font-size: 16px;
  color: white;
  margin-top: 12px;
  border-top: 3px solid;
  border-right: 3px solid;
  border-bottom: 3px solid;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  width: 17px;
  margin-left: 19px;
  height: 20px;
}

.transactions .send-icon {
  margin-left: -9px;
  margin-top: 2px;
}

.blue {
  background-color: #010c4c;
}
.grey {
  background-color: #606060;
}
.orange {
  background-color: #de9045;
}

.card-title {
  margin-bottom: 0;
}

.card .status-mark {
  font-size: 60px;
  margin: 1rem 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem;
}

.loader-blue {
  color: #010c4c;
  font-size: 3rem;
}

.mr-07 {
  margin-right: 0.7rem;
}

.table-section {
  justify-content: center;
}

.table-section .header-section {
  padding: 0 15rem 2rem 15rem;
}

.table-section .header-section .wd-title {
  color: black;
  font-size: 1.6rem;
} 

.table-section .table-container {
  width: 80% !important;
}

.table-section .wd-table-container {
  margin: 0 15rem;
  border-radius: 20px;
  box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.40);
  -webkit-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.40);
  -moz-box-shadow: 0px 0px 22px 1px rgba(0,0,0,0.40);
}

.flex-center .action {
  color: #010C4C;
  font-size: 1.3rem;
  padding: 0 0.2rem;
  cursor: pointer;
}

.details .action {
  color: #010C4C;
  font-size: 1.6rem;
  padding: 0 0.5rem;
  cursor: pointer;
}

.flex-center .action .rotate {
  transform: rotate(180deg);
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column.balance {
  font-size: 1rem;
}

.column.balance .available-amount{
  font-weight: 600;
}

.column .time {
  color: #777777;
}

.column .wd-amount {
  color: #010C4C;
  font-weight: 600;
}

.column .status {
  color: #010C4C;
}

.column .type {
  font-weight: 400;
}

.column .section-left {
  margin-right: 0.4rem;
}

.column .section-right {
  margin-left: 0.4rem;
}

.column .square {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  color: white;
  font-size: 30px;
}

.box {
  position: relative;
  background: #E6E6E6;
  border-radius: 15px;
  padding: 0.1rem 3rem;
  margin: 0.2rem 0;
  min-height: 3.5rem;
}

.box .amount {
  margin-top: 1rem;
}

.box .equivalent {
  color: #606060;
}

.box .label {
  font-size: 0.7rem;
  color: #606060;
  position: absolute;
  top: 5px;
  left: 10px;
}

.box .value {
  margin: 0.8rem 0;
  font-weight: 400
}

.box .status-mark {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.content.details {
  height: auto;
  min-height: calc(100vh - 204px);
}

.w-70 {
  width: 70% !important;
  margin-bottom: 25px;
}

.print-logo {
  padding-top: 2rem;
}

.table-section .actions-row {
  width: 60% !important;
}

.page-title {
  width: 100%;
  height: 31px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  margin-bottom: 75px;
}

.amount-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.USD-text {
  background-color: #283090;
  width: 105px;
  height: 46px;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

.USD-amount {
  width: 100%;
  height: 86px;
  font-family: 'Inter';
  font-weight: 700;
  font-size: 71px;
  line-height: 86px;
  margin-bottom: 14px;
}

.BTC-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7931A;
  width: 200px;
  height: 23px;
  border-radius: 16px;
  color: #FFFFFF;
  font-family: 'Lato';
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 25px;
}

.payment-status-icon {
  height: 80px;
  width: 80px;
  margin-bottom: 40px;
}

.invoice-text, .invoice-id, .commission-text, .commission-value {
  color: #55565E;
  width: 100%;
  height: 17.5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
}

.invoice-text, .commission-text {
  color: #55565E;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 8.5px;
}

.invoice-id, .commission-value {
  font-size: 18px;
  line-height: 21.5px;
  margin-bottom: 24px;
}

.wallet-number, .wallet-number-lightning {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D3D6E9;
  color: #55565E;
  border-radius: 10px;
  font-family: 'Arial';
  font-weight: 400;
  font-size: 13px;
  letter-spacing: -0.02em;
  margin-bottom: 35px;
  word-break: break-all;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: -0.02em;
}

.wallet-number {
  height: 42px;
  width: 25%;
}

.wallet-number-lightning {
  height: 75px;
  width: 80%;
}

.return-home-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020047;
  width: 35%;
  height: 50px;
  border-radius: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  text-transform: none;
  margin-bottom: 15px;
  cursor: pointer;
}

@media (max-width: 1090px) {
  .transactions .movements .button-blue {
    width: 15vw;
  }
  .table-section .actions-row {
    width: 100% !important;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 15vw;
  }
}

@media (max-width: 992px) {
  .content.details,
  .transactions{
    background-color: white;
    border-radius: 30px 30px 0 0;
    padding-top: 20px;
  }
  .transactions .movements .button-blue {
    width: 20vw;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 20vw;
  }
}

@media (max-width: 767px) {
  .wallet-number-lightning {
    width: 85%;
    height: 90px;
  }
}

@media (max-width: 660px) {
  .transactions .select-type .select {
    width: 65%;
  }
  .transactions .select-type {
    width: calc(93% - 22rem) !important;
  }
  .status-mark {
    margin-right: 20px;
  }
  .transactions .movements .button-blue {
    width: 34vw !important;
  }
  .transactions .movements .middle-picker {
    margin-right: 10px;
    margin-left: 0;
  }
  .content.details .value {
    font-size: 16px;
  }
  .content.details .box .status-mark {
    font-size: 1rem;
  }
  .content.details .box .status-mark svg {
    margin-right: 4px;
  }
  .content.details .type {
    font-size: 22px;
  }
  .content.details .justify-content-end {
    justify-content: center;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 34vw;
  }
  .wallet-number {
    width: 35%;
  }
}

@media (max-width: 576px) {
  .transactions .button-blue {
    width: 30vw !important;
  }
  
  .transactions .usd-btc-title {
    font-size: 2rem;
  }
  
  .transactions .usd-btc-amount {
    font-size: 20px;
  }
  
  .transactions .usd-btc-result {
    font-size: 12px;
  }
  .content.details .value {
    font-size: 14px;
  }
  .content.details .box .status-mark {
    font-size: 0.8rem;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 30vw;
  }
  .wallet-number-lightning {
    width: 85%;
    height: 110px;
  }
}

@media (max-width: 480px) {

  .transactions .content-title {
    margin-left: 22px;
    margin-right: 22px;
  }

  .transactions .guWNFd {
    padding: 0;
  }

  .transactions .row-cell {
    justify-content: initial;
  }

  .transactions .rdt_Table {
    max-width: 100% !important;
    width: 100% !important;
  }

  .transactions .button-blue {
    width: 80vw !important;
  }

  .transactions .select-type {
    margin-bottom: 20px;
  }

  .transactions .select-type .select {
    width: calc(105% - 6rem);
  }

  .transactions .select-type .type-icon {
    margin-left: 20px;
  }

  .transactions .select-input {
    padding: 0 45px 0 0;
  }

  .transactions .square {
    height: 45px;
    width: 45px;
    font-size: 25px;
  }

  .transactions .desc {
    width: 115px;
  }

  .transactions .desc .type {
    font-size: 18px;
  }
  
  .transactions .usd-btc-title {
    font-size: 1.8rem;
  }
  
  .transactions .usd-btc-amount {
    font-size: 18px;
  }
  
  .transactions .usd-btc-result {
    font-size: 12px;
  }
  
  .transactions .btc {
    margin-left: 20px;
  }
  
  .transactions .usd {
    margin-right: 20px;
  }

  .transactions .movements .button-blue {
    width: 40vw !important;
  }

  .content.details.column .section-left {
    margin-right: 0;
    flex: auto;
  }
  
  .content.details.column .section-right {
    margin-left: 0;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 40vw;
  }

  .wallet-number-lightning {
    width: 85%;
    height: 130px;
  }

  .return-home-button {
    width: 45%
  }
}

@media (max-width: 370px) {
  .transactions .movements .button-blue {
    width: 45vw !important;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 45vw;
  }
  .wallet-number {
    width: 45%;
  }
  .wallet-number-lightning {
    width: 85%;
    height: 160px;
  }
}

@media (max-width: 295px) {
  .transactions .movements .button-blue {
    width: 80vw !important;
  }
  .transactions .movements .middle-picker  {
    margin-right: 0 !important;
    margin-top: 15px !important;
  }
  .transactions .movements .mr-07 {
    margin-right: 0 !important;
  }
  .table-section .actions-row .action-btn {
    margin: 1rem 0.4rem 2rem 0.4rem;
    width: 80vw;
  }

  .wallet-number {
    width: 60%;
  }

  .wallet-number-lightning {
    width: 85%;
    height: 200px;
  }

  .return-home-button {
    width: 60%;
  }
}

::placeholder {
  color: white;
}
